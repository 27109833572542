import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import styles from '../../../../styles/SubscriptionContainer.module.scss';
import Button from '../../../../../../components/Button';
import { AppState, gqlTypes } from '../../../../../../types';
import { usePurchaseSubscriptionContext } from '../../../../../PurchaseSubscription/context/PurchaseSubscriptionContext';
import AppPaths from '../../../../../../AppPaths';
import { useHistory } from 'react-router-dom';
import { inAppCancellation as events } from '../../../../../../lib/analytics/events';
import refreshIcon from '../../../../../../assets/images/icons/icon_refresh.svg';
import { DateTime } from 'luxon';
import RenewConfirmationModal from '../../../components/RenewConfirmationModal/RenewConfirmationModal';
import { useMutation } from '@apollo/client';
import { renewSubscriptionMutation } from '../../../../../../graphql-operations';
import { getLoadingOrErrorElement } from '../../../../../../lib/util';

interface ManageSubscriptionButtonsProps {
  canRenew: boolean;
}

export default function ManageSubscriptionButtons({ canRenew }: ManageSubscriptionButtonsProps) {
  const history = useHistory();
  const { device } = usePurchaseSubscriptionContext();
  const session = useSelector((state: AppState) => state.session);
  const [error, setError] = useState('');
  const billingEmail = device.subscription?.billingEmail;
  const userEmail = session?.email;
  // Check that billingEmail and userEmail are populated before checking for equality,
  // so two undefineds don't resolve to true.
  const canManageMembership = billingEmail && userEmail && billingEmail === userEmail;

  const [renewMutation, renewSubscriptionMutationState] = useMutation<
    gqlTypes.ECOMMERCE_renewSubscription,
    gqlTypes.ECOMMERCE_renewSubscriptionVariables
  >(renewSubscriptionMutation, {
    onCompleted: (data) => {
      const success = !!data.renewSubscription?.success;
      if (!success) {
        throw new Error('Failed to renew subscription: contact customer support.');
      }
      history.push({
        pathname: AppPaths.Subscription.RenewConfirmation(device.moduleId),
        search: window.location.search,
      });
    },
  });
  const hasSubscription = device.subscriptionAccessType === gqlTypes.SubscriptionAccessType.FULL_ACCESS;
  if (!hasSubscription) {
    return null;
  }

  const loadingOrErrorElement = getLoadingOrErrorElement(
    renewSubscriptionMutationState.loading,
    renewSubscriptionMutationState.error,
  );
  if (loadingOrErrorElement) {
    return loadingOrErrorElement;
  }

  const handleRenew = () => {
    events.renewMembership({ petId: device.pet?.id });
    renewMutation({
      variables: {
        input: {
          moduleId: device.moduleId,
        },
      },
    });
  };

  const handleManageClick = () => {
    if (canManageMembership) {
      events.manageMembershipClicked({ petId: device.pet?.id });
      history.push({
        pathname: AppPaths.Subscription.PlanSelection(device.moduleId),
        search: window.location.search,
      });
    } else {
      const errMessage = billingEmail
        ? `This membership is associated with ${billingEmail}. To manage this membership, please login to that account.`
        : 'Unable to find billing email for this device. Please contact customer support.';
      setError(errMessage);
    }
  };

  return (
    <>
      <div className={styles.buttonContainer}>
        {error === '' ? (
          <>
            {canRenew ? (
              <>
                <div className={styles.renewCopy}>
                  <div className={styles.refreshIcon}>
                    <img src={refreshIcon} alt="Refresh icon" />
                  </div>
                  <div>
                    <strong>Changed your mind?</strong>
                    <br />
                    Renew to keep your Fi Membership active past{' '}
                    {DateTime.fromISO(device.subscription?.expiresAt).toFormat('MMMM d, y')}.
                  </div>
                </div>
                <RenewConfirmationModal
                  onContinue={() => handleRenew()}
                  pageName={'Membership'}
                  petId={device.pet!.id}
                />
              </>
            ) : (
              <Button className={styles.button} onClick={handleManageClick}>
                Manage Membership
              </Button>
            )}

            <Button
              className={styles.button}
              tertiary={true}
              onClick={() => {
                events.updatePaymentMethodClicked({ petId: device.pet?.id });
                history.push({
                  pathname: AppPaths.Subscription.UpdatePayment(device.moduleId),
                  search: window.location.search,
                });
              }}
            >
              Update Payment Method
            </Button>
          </>
        ) : (
          <div className={styles.error}>{error}</div>
        )}
      </div>
    </>
  );
}
